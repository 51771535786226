import React, { useEffect, useState } from 'react';
import socketIOClient, { Socket } from 'socket.io-client';
import { SocketEvent } from 'shared/build/types';
import JoinRoomForm from '../forms/JoinRoomForm';
import Room from '../Room';
import moment from 'moment';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';

const Main = (): JSX.Element => {
  const [time, setTime] = useState('');
  const [socket, setSocket] = useState<Socket>();
  const [roomCode, setRoomCode] = useState('');
  const [username, setUsername] = useState('');
  const [newRoom, setNewRoom] = useState(false);
  const [joined, setJoined] = useState(false);

  useEffect(() => {
    console.log('Connecting...');
    const uri = process.env.REACT_APP_SERVER_ROOT || 'http://127.0.0.1:4001';
    const newSocket = socketIOClient(uri);
    setSocket(newSocket);
  }, []);

  useEffect(() => {
    if (socket) {
      console.log('Connected. Setting up listeners...');

      socket.on(SocketEvent.HEARTBEAT, (time) => {
        setTime(moment(time).format('DD/MM/YYYY hh:mm:ss'));
      });

      socket.on(SocketEvent.ROOM_JOINED, (roomCode) => {
        console.log(`Joined room ${roomCode}`);
        setRoomCode(roomCode);
        setJoined(true);
      });
    }
  }, [socket]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!username || username.trim().length === 0) {
      alert('Invalid username');
      return;
    }

    if (socket?.connected) {
      if (!newRoom) {
        console.log(`Attempting to join room ${roomCode}`);
        socket.emit(SocketEvent.JOIN_ROOM, roomCode, username);
        socket.on(SocketEvent.ROOM_NOT_FOUND, () => {
          alert('No room was found with that code, please try again.');
        });
      } else {
        console.log('Attempting to create new room');
        socket.emit(SocketEvent.NEW_ROOM, username);
      }
    } else {
      alert('Failed to connect to server');
    }
  };

  return (
    <>
      <p>Server time: {time}</p>
      {joined && socket ? (
        <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
          <Room username={username} roomCode={roomCode} socket={socket} />
        </DndProvider>
      ) : (
        <div className="form-container">
          <JoinRoomForm
            newRoom={newRoom}
            roomCode={roomCode}
            setNewRoom={setNewRoom}
            setDisplayName={setUsername}
            setRoomCode={setRoomCode}
            handleSubmit={handleSubmit}
          />
        </div>
      )}
    </>
  );
};

export default Main;
