import { useEffect, useState } from 'react';
import { GameType, SocketEvent } from 'shared/build/types';
import { Socket } from 'socket.io-client';
import CopyButton from './buttons/CopyButton';
import BlackJack from './games/BlackJack';
import CrazyEights from './games/CrazyEights';
import ChooseGame from './ChooseGame';
import { Button, Typography } from '@mui/material';
import CustomDragLayer from './CustomDragLayer';

type Props = {
  username: string;
  roomCode: string;
  socket: Socket;
};

const Room = ({ username, roomCode, socket }: Props): JSX.Element => {
  const [users, setUsers] = useState<string[]>([]);
  const [game, setGame] = useState<GameType>();
  const [gameStarted, setGameStarted] = useState(false);

  useEffect(() => {
    if (socket?.connected) {
      socket.on(SocketEvent.USERS_UPDATE, (data: string[]) => {
        console.log('Received user data');
        setUsers([...data]);
      });

      socket.on(SocketEvent.GAME_CHOSEN, setGame);
      socket.on(SocketEvent.GAME_ENDED, () => {
        setGame(undefined);
        setGameStarted(false);
      });
    }
  }, []);

  const changeGame = (): void => {
    socket.emit(SocketEvent.END_GAME, roomCode);
  };

  const chooseGame = (game: GameType): void => {
    socket.emit(SocketEvent.CHOOSE_GAME, roomCode, game);
  };

  return (
    <>
      <div>
        Room Code: {roomCode}
        <CopyButton textToCopy={roomCode} />
        {game && (
          <div className="centred">
            <div className="current-game">
              <Typography className="current-game-txt" variant="h6">
                Playing {game.toString()}
              </Typography>
              <Button className="change-game-btn" variant="contained" onClick={changeGame}>
                Change Game
              </Button>
            </div>
          </div>
        )}
      </div>
      <div id="GameArea">
        <CustomDragLayer />
        {game == GameType.BLACKJACK ? (
          <BlackJack
            socket={socket}
            roomCode={roomCode}
            users={users}
            username={username}
            gameStarted={gameStarted}
            setGameStarted={setGameStarted}
          />
        ) : game == GameType.CRAZY_EIGHTS ? (
          <CrazyEights
            socket={socket}
            roomCode={roomCode}
            users={users}
            username={username}
            gameStarted={gameStarted}
            setGameStarted={setGameStarted}
          />
        ) : (
          <ChooseGame onGameSelected={chooseGame} />
        )}
      </div>
    </>
  );
};

export default Room;
