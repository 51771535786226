import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import CardValue from './CardValue';
import { Card, CardSuit } from 'shared/build/types';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

type Props = {
  value?: number;
  suit?: CardSuit;
  startFaceDown?: boolean;
  draggable?: boolean;
  isDropZone?: boolean;
  flippable?: boolean;
  small?: boolean;
  onClick?: () => void;
  onDrag?: (card: Card) => void;
  onDrop?: (card: Card) => void;
};

const PlayingCard = (props: Props): JSX.Element => {
  const ref = useRef(null);
  const [hovered, setHovered] = useState(false);
  const [faceDown, setFaceDown] = useState(props.startFaceDown ?? false);

  const isFlipped = () => (props.startFaceDown ?? false) !== faceDown;

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: 'card',
    item: { value: props.value ?? 0, suit: props.suit ?? CardSuit.CLUBS },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  if (props.draggable) {
    drag(ref);
  }

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'card',
    drop: (card) => {
      if (props.isDropZone && props.onDrop) {
        props.onDrop(card as Card);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  }));

  if (props.isDropZone) {
    drop(ref);
  }

  useEffect(() => {
    // TODO: Use this for something or get rid of it
  }, [isOver]);

  useEffect(() => {
    // Flip the currently hovered card when the user presses F
    const handleKeyDown = (e: KeyboardEvent) => {
      if (hovered && e.key === 'f') {
        setFaceDown((f) => !f);
      }
    };

    if (props.flippable ?? false) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const card = (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={props.onClick}
      style={{
        opacity: isDragging ? 0 : 1,
        cursor: props.draggable ? (isDragging ? 'grabbing' : 'grab') : undefined
      }}
      className={props.small ? 'card-small' : 'card'}>
      <div className={classNames('card-inner', props.flippable && isFlipped() && 'card-flipped')}>
        {!isDragging && (
          <div className={classNames('card-back', !props.startFaceDown && 'card-flipped')}>
            <img src="cardback.png" alt="Card back" />
          </div>
        )}
        <div className={classNames('card-front', props.startFaceDown && 'card-flipped')}>
          <div className="card-val-top">
            <CardValue value={props.value ?? 0} suit={props.suit ?? CardSuit.SPADES} />
          </div>
          {!props.small && (
            <>
              <div className="card-img">
                <img src="cardfront.png" alt="CD Logo" />
              </div>
              <div className="card-val-bot">
                <CardValue value={props.value ?? 0} suit={props.suit ?? CardSuit.SPADES} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );

  return props.draggable || props.isDropZone ? <div ref={ref}>{card}</div> : card;
};

export default PlayingCard;
