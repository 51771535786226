import { CSSProperties, useEffect } from 'react';
import { XYCoord, useDragLayer } from 'react-dnd';
import PlayingCard from './cards/PlayingCard';

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%'
};

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none'
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform
  };
}

const CustomDragLayer = (): JSX.Element | null => {
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging()
  }));

  useEffect(() => {
    // TODO: This doesn't seem to work
    if (isDragging) {
      console.log('grabbed');
      document.body.style.cursor = 'grabbing !important';
    } else {
      console.log('ungrabbed');
      document.body.style.cursor = 'normal';
    }
  }, [isDragging]);

  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        <PlayingCard value={item.value} suit={item.suit} flippable />
      </div>
    </div>
  );
};

export default CustomDragLayer;
