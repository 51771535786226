import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './components/views/Main';
import { createTheme, ThemeProvider } from '@mui/material';

function App() {
  const theme = createTheme({
    typography: {
      body1: {
        color: '#111111'
      },
      h4: {
        marginBottom: 5
      }
    },
    palette: {
      mode: 'light',
      primary: {
        main: 'rgba(138,31,31,0.95)'
      },
      secondary: {
        main: '#f17536'
      }
    }
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
