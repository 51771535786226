import { Button, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import { KeyboardEvent, useEffect } from 'react';

type Props = {
  newRoom: boolean;
  roomCode: string;
  setNewRoom: (v: boolean) => void;
  setDisplayName: (v: string) => void;
  setRoomCode: (v: string) => void;
  handleSubmit: (e: React.SyntheticEvent) => void;
};

const JoinRoomForm = ({
  newRoom,
  roomCode,
  setNewRoom,
  setDisplayName,
  setRoomCode,
  handleSubmit
}: Props): JSX.Element => {
  // Submit form on enter
  const handleDisplayNameKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  useEffect(() => {
    if (roomCode && roomCode.length === 4) {
      document.getElementById('displayName')?.focus();
    }
  }, [roomCode]);

  return (
    <form>
      <FormControlLabel
        className="switch-label"
        label={
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            New Room?
          </Typography>
        }
        control={<Switch checked={newRoom} onChange={() => setNewRoom(!newRoom)} />}
      />
      {!newRoom && (
        <TextField
          id="roomCode"
          className="form-field"
          placeholder="####"
          autoComplete="off"
          InputProps={{
            style: {
              fontSize: 48,
              letterSpacing: 50,
              paddingLeft: 54
            }
          }}
          InputLabelProps={{ style: { fontSize: 18, fontWeight: 'bold' } }}
          inputProps={{ maxLength: 4 }}
          label="Room Code"
          autoFocus={true}
          value={roomCode}
          onChange={(e) => setRoomCode(e.target.value.toUpperCase())}
          variant="filled"
        />
      )}
      <TextField
        id="displayName"
        className="form-field"
        autoComplete="off"
        InputProps={{ style: { fontSize: 48, paddingLeft: 50 } }}
        InputLabelProps={{ style: { fontSize: 18, fontWeight: 'bold' } }}
        label="Display Name"
        onChange={(e) => setDisplayName(e.target.value)}
        onKeyPress={handleDisplayNameKeyPress}
        variant="filled"
      />
      <br />
      <Button
        type="submit"
        variant="contained"
        className="form-button"
        style={{ fontSize: 48 }}
        onClick={handleSubmit}>
        {newRoom ? 'Start' : 'Join'}
      </Button>
    </form>
  );
};

export default JoinRoomForm;
