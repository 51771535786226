import { Typography } from '@mui/material';
import { Card } from 'shared/build/types';
import PlayingCard from './cards/PlayingCard';

type Props = {
  cards: Card[];
  onCardSelected?: (card: Card) => void;
  onDrag?: (card: Card) => void;
};

const Hand = ({ cards, onCardSelected, onDrag }: Props): JSX.Element => {
  return (
    <div className="hand">
      <div className="hand-inner">
        {cards.length > 0 ? (
          cards.map((card) => (
            <PlayingCard
              key={`card-${card.suit}-${card.value}`}
              value={card.value}
              suit={card.suit}
              onClick={() => onCardSelected && onCardSelected(card)}
              draggable
              onDrag={onDrag}
            />
          ))
        ) : (
          <Typography variant="h6">Your hand is empty</Typography>
        )}
      </div>
    </div>
  );
};

export default Hand;
