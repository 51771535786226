import { Button, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Card, GameType, PlayerHands, PlayerHandSizes, SocketEvent } from 'shared/build/types';
import { Socket } from 'socket.io-client';
import PlayingCard from '../cards/PlayingCard';
import Hand from '../Hand';
import UsersBar from '../UsersBar';

type Props = {
  socket: Socket;
  roomCode: string;
  users: string[];
  username: string;
  gameStarted: boolean;
  setGameStarted: (v: boolean) => void;
};

const CrazyEights = ({
  socket,
  roomCode,
  users,
  username,
  gameStarted,
  setGameStarted
}: Props): JSX.Element => {
  const [cardsInHand, setCardsInHand] = useState<Card[]>([]);
  const activePlayer = useRef<string>();
  const [roundOver, setRoundOver] = useState(false);
  const [oppHandSizes, setOppHandSizes] = useState<PlayerHandSizes>({});
  const [opponentHands, setOpponentHands] = useState<PlayerHands>();
  const [winner, setWinner] = useState<string>();
  const [topCard, setTopCard] = useState<Card>();

  const isMyTurn = () => activePlayer.current === username;

  const startGame = () => {
    if (socket?.connected) {
      setWinner(undefined);
      socket.emit(SocketEvent.START_GAME, roomCode, GameType.CRAZY_EIGHTS);
    } else {
      alert('Disconnected from server, please try again');
    }
  };

  const drawCard = (): void => {
    if (socket.connected) {
      socket.emit(SocketEvent.DRAW_CARD, roomCode, username);
    }
  };

  const playCard = (card: Card): void => {
    if (isMyTurn()) {
      socket.emit(SocketEvent.PLAY_CARD, roomCode, username, card);
    }
  };

  // Set initial hand sizes of 0 before the game starts
  useEffect(() => {
    if (!gameStarted) {
      setOppHandSizes(Object.assign({}, ...users.map((user) => ({ [user]: 0 }))));
    }
  }, [users, gameStarted]);

  // Handle socket updates
  useEffect(() => {
    const trackedEvents = [
      SocketEvent.GAME_STARTED,
      SocketEvent.NEW_TURN,
      SocketEvent.HAND_UPDATE,
      SocketEvent.OPPONENT_UPDATE,
      SocketEvent.CARD_PLAYED,
      SocketEvent.REVEAL
    ];
    trackedEvents.forEach((event) => socket.removeListener(event));

    socket.on(SocketEvent.GAME_STARTED, () => {
      console.log('Game started');
      setGameStarted(true);
      setOpponentHands(undefined);
      setRoundOver(false);
    });

    socket.on(SocketEvent.NEW_TURN, (username: string) => {
      console.log(`Next turn: ${username}`);
      activePlayer.current = username;
    });

    socket.on(SocketEvent.HAND_UPDATE, (cards: Card[]) => {
      setCardsInHand(cards);
      setOppHandSizes((x) => ({ ...x, [username]: cards.length }));
    });

    socket.on(SocketEvent.CARD_PLAYED, (card: Card) => {
      setTopCard(card);
    });

    socket.on(SocketEvent.OPPONENT_UPDATE, (opponent: string, cardsDrawn: number) => {
      console.log(`${opponent} has ${cardsDrawn} cards`);
      if (opponent !== username) {
        setOppHandSizes((x) => ({ ...x, [opponent]: cardsDrawn }));
      }
    });

    socket.on(SocketEvent.WINNER, (winner: string, hands: PlayerHands) => {
      setOpponentHands(hands);
      setWinner(winner);
      setRoundOver(true);
    });
  }, []);

  return (
    <>
      <UsersBar
        users={users}
        handSizes={oppHandSizes}
        hands={opponentHands}
        cardsRevealed={roundOver}
        activeUser={activePlayer.current}
        useShortStyle
      />
      <div className="board">
        {winner && <Typography variant="h4">{winner} wins!</Typography>}
        {(!gameStarted || roundOver) && (
          <Button style={{ marginBottom: 30 }} variant="contained" onClick={startGame}>
            {roundOver ? 'New Game' : 'Start Game!'}
          </Button>
        )}
        {gameStarted && (
          <div className="centreOfBoard">
            {/* Draw pile */}
            <PlayingCard startFaceDown onClick={drawCard} />
            {/* Discard pile */}
            <div
              style={{
                height: 240,
                display: 'inline-block',
                zIndex: 5000
              }}>
              <PlayingCard
                value={topCard?.value}
                suit={topCard?.suit}
                isDropZone
                onDrop={playCard}
              />
            </div>
          </div>
        )}
      </div>
      <Hand cards={cardsInHand} />
    </>
  );
};

export default CrazyEights;
