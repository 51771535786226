import { Button, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  Card,
  GameType,
  PlayerHands,
  PlayerHandSizes,
  PlayerLeaderboard,
  SocketEvent
} from 'shared/build/types';
import { Socket } from 'socket.io-client';
import Hand from '../Hand';
import UsersBar from '../UsersBar';

type Props = {
  socket: Socket;
  roomCode: string;
  users: string[];
  username: string;
  gameStarted: boolean;
  setGameStarted: (v: boolean) => void;
};

const BlackJack = ({
  socket,
  roomCode,
  users,
  username,
  gameStarted,
  setGameStarted
}: Props): JSX.Element => {
  const [cardsInHand, setCardsInHand] = useState<Card[]>([]);
  const [playerFinished, setPlayerFinished] = useState(false);
  const [roundOver, setRoundOver] = useState(false);
  const [score, setScore] = useState(0);
  const [oppHandSizes, setOppHandSizes] = useState<PlayerHandSizes>({});
  const [opponentHands, setOpponentHands] = useState<PlayerHands>();
  const [leaderboard, setLeaderboard] = useState<PlayerLeaderboard>();
  const [playerPosition, setPlayerPosition] = useState<number>();

  const startGame = () => {
    if (socket?.connected) {
      socket.emit(SocketEvent.START_GAME, roomCode, GameType.BLACKJACK);
    } else {
      alert('Disconnected from server, please try again');
    }
  };

  const drawCard = (): void => {
    if (socket.connected) {
      socket.emit(SocketEvent.DRAW_CARD, roomCode, username);
    }
  };

  const endTurn = (): void => {
    if (socket.connected) {
      socket.emit(SocketEvent.PLAYER_FINISHED, roomCode, username);
      setPlayerFinished(true);
    }
  };

  // Set initial hand sizes of 0 before the game starts
  useEffect(() => {
    if (!gameStarted) {
      setOppHandSizes(Object.assign({}, ...users.map((user) => ({ [user]: 0 }))));
    }
  }, [users, gameStarted]);

  // Handle socket updates
  useEffect(() => {
    const trackedEvents = [
      SocketEvent.GAME_STARTED,
      SocketEvent.HAND_UPDATE,
      SocketEvent.SCORE_UPDATE,
      SocketEvent.OPPONENT_UPDATE,
      SocketEvent.REVEAL
    ];
    trackedEvents.forEach((event) => socket.removeListener(event));

    socket.on(SocketEvent.GAME_STARTED, () => {
      console.log('Game started');
      setGameStarted(true);
      setOpponentHands(undefined);
      setRoundOver(false);
      setPlayerFinished(false);
    });

    socket.on(SocketEvent.HAND_UPDATE, (cards: Card[], score?: number) => {
      setCardsInHand(cards);
      score && setScore(score);
      setOppHandSizes((x) => ({ ...x, [username]: cards.length }));
    });

    socket.on(SocketEvent.SCORE_UPDATE, (score: number) => {
      setScore(score);
    });

    socket.on(SocketEvent.OPPONENT_UPDATE, (opponent: string, cardsDrawn: number) => {
      console.log(`${opponent} has ${cardsDrawn} cards`);
      if (opponent !== username) {
        setOppHandSizes((x) => ({ ...x, [opponent]: cardsDrawn }));
      }
    });

    socket.on(SocketEvent.REVEAL, (hands: PlayerHands, results: PlayerLeaderboard) => {
      setOpponentHands(hands);
      setLeaderboard(results);
      setRoundOver(true);
    });
  }, [socket, username]);

  useEffect(() => {
    if (score >= 21) {
      setPlayerFinished(true);
    }
  }, [score]);

  useEffect(() => {
    if (leaderboard) {
      setPlayerPosition(leaderboard.findIndex((p) => p.username === username) + 1);
    }
  }, [leaderboard, username]);

  // Quick hack to force user bar to update
  // Shouldn't be necessary once I've moved the bar up to the Room component
  useEffect(() => {
    const removedUser = Object.keys(opponentHands ?? {}).find((x) => !users.includes(x));
    if (removedUser) {
      setOpponentHands((x) => {
        x && delete x[removedUser];
        return x;
      });
      setOppHandSizes((x) => {
        x && delete x[removedUser];
        return x;
      });
    }
  }, [users]);

  return (
    <>
      <UsersBar
        users={users}
        handSizes={oppHandSizes}
        hands={opponentHands}
        cardsRevealed={roundOver}
      />
      <div className="board">
        {gameStarted && (
          <>
            <Typography variant="h4">
              Your score: {score} {score > 21 && '(Bust)'}
            </Typography>
            {playerFinished ? (
              roundOver && playerPosition ? (
                <Typography variant="h4">
                  You came {moment.localeData().ordinal(playerPosition)}
                </Typography>
              ) : (
                <Typography variant="h4">Waiting for other players...</Typography>
              )
            ) : (
              <>
                <Button variant="contained" onClick={drawCard} sx={{ marginRight: 2 }}>
                  Hit me
                </Button>
                <Button variant="contained" onClick={endTurn}>
                  I'll stick
                </Button>
              </>
            )}
          </>
        )}
        {(!gameStarted || roundOver) && (
          <Button variant="contained" onClick={startGame}>
            {roundOver ? 'New Game' : 'Start Game!'}
          </Button>
        )}
      </div>
      <Hand cards={cardsInHand} />
    </>
  );
};

export default BlackJack;
