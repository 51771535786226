import { Typography } from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';
import { Card, CardSuit, PlayerHands, PlayerHandSizes } from 'shared/build/types';
import PlayingCard from './cards/PlayingCard';

type Props = {
  users: string[];
  activeUser?: string;
  handSizes: PlayerHandSizes;
  hands: PlayerHands | undefined;
  cardsRevealed: boolean;
  useShortStyle?: boolean;
};

type PlayerCards = { [user: string]: Card[] };

const UsersBar = ({
  users,
  activeUser,
  handSizes,
  hands,
  cardsRevealed,
  useShortStyle
}: Props): JSX.Element => {
  const [unrevealedHands, setUnrevealedHands] = useState<PlayerCards>({});
  const activeUserStyle: CSSProperties = { color: 'gold' };

  useEffect(() => {
    const newData: PlayerCards[] = Object.entries(handSizes).map(([username, handSize]) => {
      const fakeCards = [...Array(handSize)].map(() => ({
        value: 0,
        suit: CardSuit.SPADES
      }));
      return { [username]: fakeCards };
    });
    setUnrevealedHands(Object.assign({}, ...newData));
    // Must force an update if the active player changes
  }, [handSizes, users]);

  const createCardComponent = (card: Card, key: number) => {
    return (
      <PlayingCard
        small
        key={key}
        value={card.value}
        suit={card.suit}
        startFaceDown={!cardsRevealed}
      />
    );
  };

  return (
    <div className="users-bar-container">
      <div className="users-bar">
        <ul>
          {cardsRevealed && hands
            ? Object.keys(hands).map((user) => {
                return (
                  <li key={user}>
                    <div style={activeUser === user ? activeUserStyle : undefined}>{user}</div>
                    {hands[user].map((card, i) => createCardComponent(card, i))}
                  </li>
                );
              })
            : Object.keys(unrevealedHands).map((user) => {
                return (
                  <li key={user}>
                    <div style={activeUser === user ? activeUserStyle : undefined}>{user}</div>
                    {useShortStyle ? (
                      <div style={{ marginRight: 15 }}>
                        <Typography style={{ float: 'left', marginTop: 15 }} fontSize={32}>
                          {unrevealedHands[user].length}
                        </Typography>
                        {createCardComponent({ value: 1, suit: CardSuit.SPADES }, 0)}
                      </div>
                    ) : (
                      unrevealedHands[user].map((card, i) => createCardComponent(card, i))
                    )}
                  </li>
                );
              })}
        </ul>
      </div>
    </div>
  );
};

export default UsersBar;
