import { faCheck, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Typography, useTheme } from '@mui/material';
import { useState } from 'react';

type Props = {
  textToCopy: string;
};

const CopyButton = ({ textToCopy }: Props): JSX.Element => {
  const theme = useTheme();
  const [textCopied, setTextCopied] = useState(false);

  const copyText = () => {
    navigator.clipboard.writeText(textToCopy);
    setTextCopied(true);
    setTimeout(() => setTextCopied(false), 10000);
  };

  return (
    <IconButton
      size="medium"
      className="copy-btn"
      disabled={textCopied}
      onClick={() => !textCopied && copyText()}>
      <Typography fontSize={14} fontWeight="bold" color={theme.palette.primary.main}>
        {textCopied ? 'Copied!' : 'Copy'}
        <FontAwesomeIcon
          size="lg"
          icon={textCopied ? faCheck : faCopy}
          className="inline-icon"
          color={theme.palette.primary.main}
        />
      </Typography>
    </IconButton>
  );
};

export default CopyButton;
