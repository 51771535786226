export enum CardSuit {
  HEARTS = "hearts",
  DIAMONDS = "diamonds",
  SPADES = "spades",
  CLUBS = "clubs"
}

export type Card = {
  value: number;
  suit: CardSuit;
}

export enum GameType {
  BLACKJACK = "BlackJack",
  CRAZY_EIGHTS = "Crazy Eights"
}

export enum SocketEvent {
  CONNECT = "connection",
  DISCONNECT = "disconnect",
  HEARTBEAT = "heartbeat",
  NEW_ROOM = "newRoom",
  JOIN_ROOM = "joinRoom",
  ROOM_JOINED = "roomJoined",
  ROOM_NOT_FOUND = "roomNotFound",
  USERS_UPDATE = "usersUpdate",
  CHOOSE_GAME = "chooseGame",
  GAME_CHOSEN = "gameChosen",
  START_GAME = "startGame",
  GAME_STARTED = "gameStarted",
  END_GAME = "endGame",
  GAME_ENDED = "gameEnded",
  DRAW_CARD = "drawCard",
  PLAY_CARD = "playCard",
  CARD_PLAYED = "cardPlayed",
  HAND_UPDATE = "handUpdate",
  SCORE_UPDATE = "scoreUpdate",
  OPPONENT_UPDATE = "opponentUpdate",
  NEW_TURN = "newTurn",
  PLAYER_FINISHED = "playerFinished",
  REVEAL = "reveal",
  WINNER = "winner",
}

export type Args = unknown[];

export type PlayerHandSizes = { [username: string]: number };
export type PlayerHands = { [username: string]: Card[] };
export type PlayerLeaderboard = { username: string, score: number }[];
