import { Typography } from '@mui/material';
import { CardSuit } from 'shared/build/types';
import { GiHearts, GiDiamonds, GiSpades, GiClubs } from 'react-icons/gi';

type Props = {
  value: number;
  suit: CardSuit;
};

const cardDisplayValues: { [v: number]: string } = {
  1: 'A',
  10: 'J',
  11: 'Q',
  12: 'K'
};

const cardSuitIcons: { [s in CardSuit]: JSX.Element } = {
  [CardSuit.HEARTS]: <GiHearts />,
  [CardSuit.DIAMONDS]: <GiDiamonds />,
  [CardSuit.SPADES]: <GiSpades />,
  [CardSuit.CLUBS]: <GiClubs />
};

const CardValue = ({ value, suit }: Props): JSX.Element => {
  const displayValue = cardDisplayValues[value] ?? `${value}`;
  const icon = cardSuitIcons[suit];
  const color = [CardSuit.HEARTS, CardSuit.DIAMONDS].includes(suit) ? 'red' : 'black';

  return (
    <>
      <div>
        <Typography fontSize={20} fontWeight="bold" color={color}>
          {displayValue}
        </Typography>
      </div>
      <div>
        <Typography fontSize={20} fontWeight="bold" color={color}>
          {icon}
        </Typography>
      </div>
    </>
  );
};

export default CardValue;
