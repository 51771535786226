import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { GameType } from 'shared/build/types';

type GameGridItem = {
  type: GameType;
  name: string;
  description: string;
};

type Props = {
  onGameSelected: (game: GameType) => void;
};

const ChooseGame = ({ onGameSelected: setGame }: Props): JSX.Element => {
  const games: GameGridItem[] = [
    {
      type: GameType.BLACKJACK,
      name: 'BlackJack',
      description: 'Get 21 to win!'
    },
    {
      type: GameType.CRAZY_EIGHTS,
      name: 'Crazy Eights',
      description: "It's like Uno, but worse!"
    }
  ];

  return (
    <div className="grid-container">
      <Grid container spacing={2}>
        {games.map((g) => (
          <Grid item>
            <Card sx={{ width: 275, backgroundColor: '#222222' }}>
              <CardContent style={{ color: 'white' }}>
                <Typography variant="h4">{g.name}</Typography>
                <Typography variant="body2">{g.description}</Typography>
              </CardContent>
              <CardActions>
                <Button variant="contained" sx={{ width: '100%' }} onClick={() => setGame(g.type)}>
                  Play!
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ChooseGame;
